@use '@/app/queries' as *;

.container {
  display: flex;

  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 2px var(--cst-stepperbutton-outline-default);
  background: var(--cst-stepperbutton-background-default);
  border-radius: var(--cornerradius8);
  border: none;
  outline: none;
  text-align: center;
  color: rgba(105, 65, 198, 1);
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 700;

  transition: all 0.3s;

  width: 36px;
  height: 36px;

  @include min-428-break {
    width: 44px;
    height: 44px;
    font-size: 16px;
  }

  @include min-1024-break {
    width: 32px;
    height: 32px;
    font-size: 12px;
    font-weight: 400;
    border-radius: var(--cornerradius6);
  }

  @include min-1440-break {
    width: 42px;
    height: 42px;
    font-size: 16px;
    border-radius: var(--cornerradius8);
  }

  &:hover {
    box-shadow: inset 0 0 0 2px var(--cst-stepperbutton-outline-hover);
    background: white;
  }
  &:active {
    border: 2px solid var(--cst-stepperbutton-outline-pressed);
    background: var(--cst-stepperbutton-background-pressed);
    box-shadow: inset 0 0 0 2px rgba(105, 65, 198, 0.2);
  }
  &:focus {
    border: 2px solid var(--cst-input-search-outline-typing);
    background: var(--cst-stepperbutton-background-pressed);
    box-shadow: inset 0 0 0 2px rgba(105, 65, 198, 0.2);
  }
  &-disabled {
    pointer-events: none;
    box-shadow: inset 0 0 0 2px var(--cst-stepperbutton-outline-disabled);
    background: var(--cst-inputstepper-background-disabled);
    color: rgba(105, 65, 198, 0.1);
  }
}
