@use '@/app/queries' as *;
@use '@fixed-size' as *;

.container {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--cst-stepperbutton-outline-default);
  background: var(--cst-stepperbutton-background-default);
  border-radius: var(--cornerradius8);
  cursor: pointer;

  @include singleFixedSize(36px);

  @include min-428-break {
    @include singleFixedSize(44px);
  }

  @include min-1024-break {
    @include singleFixedSize(32px);
    border-radius: var(--cornerradius6);
  }

  @include min-1440-break {
    @include singleFixedSize(42px);
    border-radius: var(--cornerradius8);
  }

  &:hover {
    border: 2px solid var(--cst-stepperbutton-outline-hover);
    background: white;
  }
  &:active {
    border: 2px solid var(--cst-stepperbutton-outline-pressed);
    background: var(--cst-stepperbutton-background-pressed);
    box-shadow: 0 0 0 2px rgba(105, 65, 198, 0.2);
  }
  &-disabled {
    pointer-events: none;
    border: 2px solid var(--cst-stepperbutton-outline-disabled);
    background: var(--cst-stepperbutton-background-disabled);

    .icon {
      opacity: 0.2;
    }
  }
}

.icon {
  color: #6941c6;
  @include singleFixedSize(14px);

  @include min-428-break {
    @include singleFixedSize(18px);
  }

  @include min-1024-break {
    @include singleFixedSize(16px);
  }

  @include min-1440-break {
    @include singleFixedSize(18px);
  }
}
