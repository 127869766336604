@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  position: relative;
  align-self: center;
  &.status-unavailable {
    pointer-events: none;
    opacity: 1;
  }
}
