@use '@/shared/ui/button/adaptive' as *;
@use '@button' as *;
@use '@fixed-size' as *;
@use '@queries' as *;
@use '@typography' as *;

.action-btn {
  @include buttonSizes('32', '32', '32', '34', '40', '42');
}

.quantity-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 4px;
  button,
  input {
    @include typography-s;
    @include weight-bold;
    border-radius: var(--cornerradius8);
    @include singleFixedSize(34px);
    span {
      @include singleFixedSize(14px);
    }
  }

  input {
    flex: 1;
    @include fixedSize(inherit, 34px);
  }

  @include min-1440-break {
    button,
    input {
      @include typography-base;
      border-radius: var(--cornerradius10);
      @include singleFixedSize(40px);
      span {
        @include singleFixedSize(18px);
      }
    }

    input {
      flex: 1;
      @include fixedSize(inherit, 40px);
    }
  }
  @include min-1920-break {
    button,
    input {
      @include typography-m;
      @include singleFixedSize(42px);
    }

    input {
      flex: 1;
      @include fixedSize(inherit, 42px);
    }
  }
}
